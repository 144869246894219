import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import cn from "../styles/about.module.scss"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      avatar: file(relativePath: { eq: "sofia-lima-sousa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 235) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="About" />
      <div className={cn.content}>
        <div className={cn.avatarWrapper}>
          <Img
            fluid={data.avatar.childImageSharp.fluid}
            alt="Sofia Lima Sousa"
            className={cn.avatar}
          />
        </div>
        <div className={cn.textWrapper}>
          <h1>Sofia Lima Sousa</h1>
          <p>Hi, my name is Sofia!</p>
          <p>I was born in Porto, Portugal where I currently live.</p>
          <p>
            Ever since I was a little girl I have been dreaming and creating the
            stories that appear in my imagination and in my heart.
          </p>
          <p>
            Today I work as a Graphic Designer and Illustrator and I go where my
            imagination will take me.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
